<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="federations.showError"
    >
        <h1 class="text-center">{{ federation.name }}</h1>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <h2 class="text-center mb-4">
                    {{ $t("federations.federation.informations") }}
                    <v-btn
                        v-if="canUpdate"
                        text
                        :to="{
                            name: 'UpdateFederation',
                            params: {
                                federationId: federationId,
                            },
                        }"
                    >
                        <v-icon small color="primary">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </h2>

                <v-row>
                    <v-col cols="12" md="6">
                        <p class="mb-0" v-if="federation.address">
                            <strong>
                                <v-icon dense>
                                    mdi-mailbox-open-outline
                                </v-icon>
                                {{
                                    $t("federations.federation.address.street")
                                }}
                                :
                            </strong>
                            {{ federation.address.street }}
                            {{ federation.address.post_code }}
                            {{ federation.address.city }}
                        </p>
                        <p class="mb-0">
                            <strong>
                                <v-icon dense>
                                    mdi-phone
                                </v-icon>
                                {{ $t("federations.federation.phone") }}
                                :
                            </strong>
                            {{ federation.phone }}
                        </p>
                        <p class="mb-0">
                            <strong>
                                <v-icon dense>
                                    mdi-at
                                </v-icon>
                                {{ $t("federations.federation.email") }}
                                :
                            </strong>
                            {{ federation.email }}
                        </p>
                        <p class="mb-0" v-if="federation.siret">
                            <strong>
                                <v-icon dense>
                                    mdi-card-bulleted
                                </v-icon>
                                {{ $t("federations.federation.siret") }}
                                :
                            </strong>
                            {{ federation.siret }}
                        </p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        sm=6
                        md="4"
                        v-for="board in boards"
                        :key="board"
                    >
                        <v-card outlined class="mt-2" v-if="federation[board]">
                            <v-card-title class="pb-0">
                                {{ $t("federations.federation." + board) }}
                            </v-card-title>
                            <v-card-text>
                                <v-icon dense>
                                    mdi-badge-account-horizontal
                                </v-icon>

                                <span>
                                    {{ federation[board].firstname }}
                                    {{ federation[board].lastname }}
                                </span>
                                <br />

                                <v-icon dense>
                                    mdi-at
                                </v-icon>
                                {{ federation[board].email }}
                                <br />

                                <v-icon dense>mdi-phone</v-icon>
                                {{ federation[board].phone }}
                                <br />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <h2 class="text-center mb-4">
                    {{ $t("federations.federation.associations") }}
                </h2>

                <v-data-table
                    class="mt-4"
                    :headers="headers"
                    :items="associations"
                    disable-pagination
                    hide-default-footer
                    @click:row="onShowAssociation"
                    mobile-breakpoint="0"
                >
                </v-data-table>
            </v-card-text>
        </v-card>
    </loader>
</template>

<script>
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    props: {
        federationId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            canUpdate: false,
            canSeeAssociations: false,
            federation: {},
            associations: [],

            boards: [
                "president",
                "director",
                "representative",
                "contact",
                "treasurer",
            ],

            isLoading: true,
            error: false,

            headers: [
                {
                    text: this.$t("federations.federation.associationName"),
                    value: "name",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("federations.federation.associationYear"),
                    value: "year",
                    class: "primary white--text",
                    sortable: true,
                },
            ],
        };
    },

    mounted() {
        this.getFederation();
    },

    methods: {
        getFederation() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getFederation", this.federationId)
                .then((data) => {
                    this.canUpdate = data.data.canUpdate;
                    this.canSeeAssociations = data.data.canSeeAssociations;
                    this.federation = data.data.federation;
                    this.associations = data.data.associations.sort((a, b) => {
                        if (a.year == null) {
                            return 1;
                        } else if (b.year == null) {
                            return -1;
                        } else if (
                            b.year.toString().localeCompare(a.year) == 0
                        ) {
                            return a.name.localeCompare(b.name);
                        } else {
                            return b.year.toString().localeCompare(a.year);
                        }
                    });

                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 404
                    ) {
                        this.$router.push({ name: "404" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        onShowAssociation(association) {
            if (this.canSeeAssociations) {
                this.$router.push({
                    name: "Association",
                    params: {
                        associationId: association.id,
                    },
                });
            }
        },
    },
};
</script>
